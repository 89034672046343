.search__container {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: .2em;
    padding-bottom: .2em;
}

.search__container input {

    line-height: 1.75;
    border: 2px solid grey;
    border-radius: 5px;
    font-size: calc(8px + 2vmin);
    padding: .25em;
    text-indent: .25em;
    margin-left: .75em;
}

.search__missing--label {

    text-align: center;
}

.search__icon--container {

    position: relative;
}

.search__icon--container .search__icon {

    position: absolute;
    top: calc(.3em + 1vmin);
    right: calc(.2em + 1vmin);
    width: .5em;
    width: calc(.5em + 1vmin);
    height: .5em;
    height: calc(.5em + 1vmin);
}

.search__icon--menu-container {

    margin-right: .5em;
    margin-top: .25em;
}

.search__icon--menu {

    width: .8em;
    width: calc(.8em + 1vmin);
    height: .8em;
    height: calc(.8em + 1vmin);
}

.search__spinner--wrapper {

    margin: 0 0 .75em .5em;
}

.search__result--container {
    
    display: flex;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    padding-bottom: .1em;
}

.search__result {

    display: flex;
    justify-content: center;
    align-items: center;
    color: #0a162f;
    width: 90%;
    padding-left: .25em;
}

.search__result .search__icon {

    width: .5em;
    width: calc(.5em + 1vmin);
    height: .5em;
    height: calc(.5em + 1vmin);
    margin-right: .5em;
}

.search__result div {

    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: .5em;
    font-weight: bold;
}
