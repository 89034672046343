@import '../sass/constants.scss';

.home__weather--container {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 80vh;
    padding-right: .5em;
    padding-top: 1em;
    margin-top: calc(30px + 2vmin);
}

.home__search--label {

    color: $ui-white;
    padding-top: 30vh;
    text-align: center;
}