@import '../sass/constants.scss';

.current__container {

    padding-left: .5em;
    margin-top: .25rem;
}

.current__header--label {

    padding-bottom: .25em;
    color: $ui-white;
}

.current__rightNow, .current__hourly--wrapper {

    font-size: calc(12px + 2vmin);
}
.current__rightNow {
    
    background-color: $ui-off-white;
    padding: .25em 0 .25em .5em;
    border: 2px solid $ui-light-blue;
    border-radius: $ui-border-radius;
    margin-top: .25em;
    box-shadow: $ui-box-shadow;
}

.current__rightNow span {

    font-size: calc(7px + 2vmin);
}

.current__rightNow--label {
    
    font-weight: 500;
    border-bottom: 1px solid $ui-light-blue;
    margin-bottom: .25em;
}

.current__rightNow--flex {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div:first-child {
        flex: 3 1;
    }
    > div:nth-child(2) {
        flex: .5 1;
        display: flex;
        justify-content: center;
    }
}

.current__temps--wrapper {
    display: flex;
    align-items: center;
}

.current__temps--wrapper div:first-of-type {
    margin-right: .25rem;
}

.current__rise--container {

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    margin-left: -.75em;
    margin-top: -.5rem;
    margin-bottom: -.5rem;
}

.current__hourly--wrapper {

    display: flex;
    justify-content: flex-start;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-top: .25em;
    border-radius: $ui-border-radius;
    min-height: 60px;
    box-shadow: $ui-box-shadow-strong;
}
.current__hourly-appear {
    
    opacity: 0.01;
}
  
.current__hourly-appear.current__hourly-appear-active {
    
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.current__hourly-enter {
    
    opacity: 0.01;
}
  
.current__hourly-enter.current__hourly-enter-active {
    
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}
  
.current__hourly-leave {
    
    opacity: 1;
}
  
.current__hourly-leave.current__hourly-leave-active {
    
    opacity: 0.01;
    transition: opacity 300ms ease-out;
}