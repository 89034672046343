@import '../sass/constants.scss';

.tomorrow__container {

    margin-top: 1rem;
    padding-left: .5em;
}

.tomorrow__header--label {

    padding-bottom: .25em;
    color: $ui-white;
}

.tomorrow__tile--container {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: calc(10px + 2vmin)
}