@import '../sass/constants.scss';

.about__container {

    min-height: 110vh;
    padding-right: .5em;
    padding-left: .5em;
    padding-top: calc(6rem + 2vmin);
    color: $ui-white;
}

.about__header--label {

    padding-bottom: .25em;
}

.about__content {

    font-size: calc(12px + 2vmin);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.about__content a {

    color: $ui-white;
    padding-left: .25em;
    padding-right: .25em;
}

.about__content--footer {

    font-size: calc(4px + 2vmin);
}

.about__icon {

    height: 4em;
    height: calc(4em + 2vmin);
    width: 4em;
    width: calc(4em + 2vmin);
}