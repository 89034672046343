@import '../sass/constants.scss';

.seven-day__container {

    padding-left: .5em;
    margin-top: 1rem;
    padding-bottom: 1em;
}

.seven-day__header--label {

    padding-bottom: .25em;
    color: $ui-white;
}

.seven-day__tile--container {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: calc(10px + 2vmin)
}
