@import '../sass/constants.scss';

.app__container {
	
    background-color: $ui-blue;
    min-height: 100vh;
    min-width: 350px;
    font-size: calc(15px + 2vmin);
    color: $ui-dark-blue;
}

.app__fixed--menu {

    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $ui-off-white;
    border-bottom: 1px $ui-light-blue solid;
    box-shadow: 0 0 5px;
    z-index: 1000;
}

.app__loading--container {

    display: flex;
    justify-content: center;
    padding-top: 2em;
    height: 120vh;
}
