.app__search {

    display: flex;
    align-content: center;
    justify-content: center;
}

.app__weather--container {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 80vh;
    padding-right: .5em;
    padding-top: 1em;
    margin-top: calc(30px + 2vmin);
}

.app__loading--container {

    display: flex;
    justify-content: center;
    padding-top: 2em;
}

.app__search--label {

    padding-top: 30vh;
    text-align: center;
}

.app__menu-appear {
    
    opacity: 0.01;
}
  
.app__menu-appear.app__menu-appear-active {
    
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.app__menu-enter {
    
    opacity: 0.01;
}
  
.app__menu-enter.app__menu-enter-active {
    
    opacity: 1;
    transition: opacity 500ms ease-in;
}
  
.app__menu-leave {
    
    opacity: 1;
}
  
.app__menu-leave.app__menu-leave-active {
    
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}