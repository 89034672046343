@import '../sass/constants.scss';

.hourly__wrapper {

    display: flex;
    align-items: center;
    padding: .15em;
}

.hourly__container {

    display: flex;
    flex: 3;
    flex-direction: column;
    min-height: 60px;
    border: 1px solid $ui-light-blue;
    border-radius: $ui-border-radius;
    background-color: $ui-off-white;
}

.hourly__header {

    font-weight: 500;
    padding-left: .5em;
}

.hourly__detail {

    padding-left: .5em;
    padding-right: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}