@import '../sass/constants.scss';

.alert__wrapper {

    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #0a162f;
    background-color: $ui-off-white;
    border: 1px solid $ui-light-blue;
    border-radius: $ui-border-radius;
    padding: .15em;
    margin-top: .25em;
    box-shadow: $ui-box-shadow;
}

.alert__container {

    display: flex;
    flex: 3;
    flex-direction: column;
}

.alert__header {

    font-weight: 500;
    padding-left: .5em;
    padding-bottom: .5em;
}

.alert__header > span {
    border-bottom: 1px solid $ui-light-blue;
    display: inherit;
}

.alert__detail {

    padding-left: .5em;
}

.alert__detail span {

    padding-right: .25em;
    text-transform: uppercase;
}