@import '../sass/constants.scss';

.tile__wrapper {
    background-color: $ui-off-white;
    border: 1px solid $ui-light-blue;
    border-radius: $ui-border-radius;
    padding-top: .25em;
    margin-top: .25em;
    box-shadow: $ui-box-shadow;
    > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.tile__container {

    display: flex;
    flex: 3;
    flex-direction: column;
}

.tile__header {

    font-weight: 500;
    padding-left: .5em;
    padding-bottom: .5em;
}

.tile__detail {

    padding-left: .5em;
    margin-bottom: -.5rem;
    height: 65px;
    max-height: 65px;
}

.tile__detail span {

    padding-right: .25em;
}

.tile__rise--container {

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    margin-left: -.75em;
    margin-top: -.75rem;
}

.tile__img--container {

    flex: .5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tile__img {

    color: $ui-white;
}